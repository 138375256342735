<template>
  <v-dialog persistent v-if="object" width="800" v-model="dialog">
    <v-card min-height="700">
      <!-- toolbar -->
      <v-toolbar dense height="50" dark color="primary">
        <span>复制目标</span>
        <v-spacer></v-spacer>
        <v-icon @click="dialog=false">mdi-close</v-icon>
      </v-toolbar>
      <v-container fluid>
        <!-- step of wizard -->
        <v-stepper elevation="0" v-model="step">
          <!-- header of stepper -->
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step>1" step="1">
              复制基本信息
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step>2" step="2">
              复制KR
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">
              复制任务
            </v-stepper-step>
          </v-stepper-header>
          <!-- items of stepper -->
          <v-stepper-items>
            <!-- step1-->
            <v-stepper-content step="1">
              <v-container fluid>
                <v-row class="align-center my-0">
                  <span class="text-caption mr-5 ml-4">* 名称</span>
                  <v-text-field v-model="object.ob_name"></v-text-field>
                </v-row>
                <v-row class="align-center my-0">
                  <span class="text-caption mr-5">起止时间</span>
                  <v-radio-group row v-model="cycleType">
                    <v-radio label="年度" value="year"></v-radio>
                    <v-radio label="季度" value="quarter"></v-radio>
                    <v-radio label="月度" value="month"></v-radio>
                    <v-radio label="自定义" value="date"></v-radio>
                  </v-radio-group>
                </v-row>
                <!-- year -->
                <v-row class="my-0" v-if="cycleType=='year'">
                  <span class="mx-3"></span>
                  <v-col cols="3">
                    <v-select dense v-model="cycleYear" class="ml-12" :items="years" ></v-select>
                  </v-col>                  
                </v-row>
                <!-- quarter -->
                <v-row class="my-0" v-if="cycleType=='quarter' || cycleType=='month'">
                  <v-col cols="3">
                    <v-select dense v-model="cycleYear" class="ml-12 my-0" :items="years" ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select dense v-model="cycle" class="ml-12 my-0" :items="cycles" ></v-select>  
                  </v-col>                  
                </v-row>
                <!-- date -->
                <v-row class="my-0" v-if="cycleType=='date'">
                  <span class="mx-3"></span>
                  <date-range-picker ref="picker" opens="right" control-container-class="my-0 ml-12" :locale-data="{ 
                                      firstDay: 1,
                                      format: 'yyyy-mm-dd',
                                      applyLabel: '确定',
                                      cancelLabel: '取消',
                                      daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                      monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                  }" 
                                  minDate="2018-01-01" 
                                  maxDate="2050-12-31" 
                                  :dateRange="{startDate: object.ob_start_date, endDate: object.ob_end_date}" 
                                  :ranges="false" 
                                  @update="changeDate" 
                                  :append-to-body="true">
                    <template v-slot:input="picker">
                      {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                    </template>
                  </date-range-picker>
                </v-row>
                <v-row class="my-0 align-center">
                  <span class="text-caption mr-5">目标类型</span>
                  <v-radio-group row v-model="object.ob_type">
                    <v-radio label="公司" :value="0"></v-radio>
                    <v-radio label="部门" :value="1"></v-radio>
                    <v-radio label="团队" :value="2"></v-radio>
                    <v-radio label="个人" :value="3"></v-radio>
                  </v-radio-group>
                </v-row>
                <!-- object owner -->
                <v-row class="my-0 align-center">
                  <span class="text-caption mr-8">负责人</span>
                  <v-menu :nudge-width="-350" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="justify-start pl-1" v-bind="attrs" v-on="on" color="grey" width="650" outlined>
                        <v-avatar color="#808000" size="26"><span class="white--text">{{ object.ob_owner | surename }}</span></v-avatar>
                        <span>{{ object.ob_owner | username }}</span>
                      </v-btn>
                    </template>
                      <UserPicker @pick="setOwner" />
                  </v-menu>                  
                </v-row>
                <!-- object participant -->
                <v-row class="my-4 align-center">
                  <span class="text-caption mr-5">内部成员</span>
                  <v-menu :nudge-width="-350" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="justify-start pl-1"  color="grey" width="650" outlined>
                        <v-icon v-bind="attrs" v-on="on" large color="green">mdi-plus-circle</v-icon>
                        <v-avatar @click="removeMember(member)" class="white--text mx-1" color="#808000" size="26" v-for="(member, i) in members" :key="i">{{ member | surename }}</v-avatar>
                      </v-btn>
                    </template>
                      <UserPicker @pick="addMember" />
                  </v-menu>
                </v-row>
                <!-- align -->
                <v-row class="align-center my-3">
                  <span class="text-caption mr-5">对齐目标</span>                  
                  <v-btn @click="openAlignDlg" class="justify-start pl-1" color="grey" width="650" outlined>{{ parentName }}</v-btn>                       
                </v-row>
                <!-- visible -->
                <v-row class="align-center my-3">
                  <span class="text-caption mr-5">可见范围</span>
                  <v-radio-group row v-model="object.ob_visible_type">
                    <v-radio label="私密" :value="1"></v-radio>
                    <v-radio label="公开" :value="0"></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row class="mt-5">
                  <v-spacer></v-spacer>
                  <v-btn class="mx-1 my-1" @click="close">取消</v-btn>
                  <v-btn @click="step=2" color="primary" class="mx-1 my-1">下一步</v-btn>  
                </v-row>                
              </v-container>              
            </v-stepper-content>
            <!-- step2 -->
            <v-stepper-content step="2">
              <v-card>
                <div style="height:430px;overflow:auto;" class="justify-start pa-3">
                  <v-row style="height:70px;" class="orange lighten-3 pa-3 align-center">
                    <span class="text-caption mx-3">是否复制目标下的任务</span>
                    <v-radio-group row v-model="krCopyMode">
                      <v-radio label="复制" :value="0"></v-radio>
                      <v-radio label="不复制" value="1"></v-radio>
                    </v-radio-group>
                  </v-row>
                  <v-row v-if="krCopyMode==0" class="my-2 align-center" v-for="(kr, i) in object.ob_results" :key="i">
                    <!-- kr owner-->
                    <v-menu offset-y>
                      <template v-slot:activator="{on, attrs}">
                        <v-avatar v-bind="attrs" v-on="on" style="cursor:pointer;" size="50" color="green"><span class="text-caption white--text">{{ kr.kr_owner | username }}</span></v-avatar>
                      </template>
                      <UserPicker @pick="setKrOwner($event, i)" />
                    </v-menu>
                    <v-text-field class="mx-2" v-model="kr.kr_name"></v-text-field>
                    <v-icon>mdi-calendar</v-icon>
                    <date-range-picker ref="picker" opens="left" control-container-class="" :locale-data="{ 
                                        firstDay: 1,
                                        format: 'yyyy-mm-dd',
                                        applyLabel: '确定',
                                        cancelLabel: '取消',
                                        daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                        monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                    }" 
                                    minDate="2018-01-01" 
                                    maxDate="2050-12-31" 
                                    :dateRange="{startDate: kr.kr_start_date, endDate: kr.kr_end_date}" 
                                    :ranges="false" 
                                    @update="changeKrDate($event, i)" 
                                    :append-to-body="true">
                      <template v-slot:input="picker">
                        {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                      </template>
                    </date-range-picker>
                  </v-row>
                </div>
                <div class="pa-2">
                  <v-row class="mt-5">
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1 my-1" @click="close">取消</v-btn>
                    <v-btn @click="step=3" color="primary" class="mx-1 my-1">下一步</v-btn>  
                  </v-row>
                </div>                
              </v-card>
            </v-stepper-content>
            <!-- step3 -->
            <v-stepper-content step="3">
              <v-container fluid>
                <div style="height:430px;overflow-y:auto;overflow-x:hidden;">
                  <v-row style="height:70px;" class="orange lighten-3 pa-3 align-center">
                    <span class="text-caption mx-3">是否复制目标下的任务</span>
                    <v-radio-group row v-model="taskCopyMode">
                      <v-radio label="复制一级任务" :value="0"></v-radio>
                      <v-radio label="复制全部任务" :value="1"></v-radio>
                      <v-radio label="不复制" value="2"></v-radio>
                    </v-radio-group>
                  </v-row>
                  <!-- level 1 tasks -->
                  <v-row class="pa-2" v-if="taskCopyMode==0">
                    <v-data-table :headers="headers" :items="objectTasks" hide-default-footer>
                      <template v-slot:[`item.task_name`]="{item}">
                        <v-text-field dense v-model="item.task_name" height="50"></v-text-field>
                      </template>
                      <template v-slot:[`item.task_owner`]="{item}">
                        <v-menu :nudge-width="-350" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <v-icon large color="blue">mdi-account-outline</v-icon>
                              <span>{{ item.task_owner | username }}</span>
                            </div>
                          </template>
                            <UserPicker @pick="changeTaskOwner($event, item.task_id)" />
                        </v-menu>
                      </template>
                      <template v-slot:[`item.date`]="{item}">
                        <date-range-picker ref="picker" opens="left" control-container-class="" :locale-data="{ 
                                        firstDay: 1,
                                          format: 'yyyy-mm-dd',
                                          applyLabel: '确定',
                                          cancelLabel: '取消',
                                          daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                          monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                      }" 
                                      minDate="2018-01-01" 
                                      maxDate="2050-12-31" :dateRange="{startDate: item.task_start_date, endDate: item.task_end_date}" 
                                      :ranges="false" 
                                      @update="changeTaskDate($event, item.task_id)" 
                                      :append-to-body="true">
                        <template v-slot:input="picker">
                          {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                        </template>
                      </date-range-picker>
                      </template>
                    </v-data-table>
                  </v-row>
                  <!-- all tasks -->
                  <v-row class="pa-2" v-if="taskCopyMode==1">
                    <zk-table 
                      :data="objectTasks" 
                      :columns="headers1" 
                      children-prop="task_tasks"
                      index-text="#"
                      :stripe="props.stripe"
                      :border="props.border"
                      :show-header="props.showHeader"
                      :show-summary="props.showSummary"
                      :show-row-hover="props.showRowHover"
                      :show-index="props.showIndex"
                      :tree-type="props.treeType"
                      :is-fold="props.isFold"
                      :expand-type="props.expandType"
                      :selection-type="props.selectionType">
                        <template slot="name" scope="scope">
                          <v-text-field dense v-model="scope.row.task_name"></v-text-field>
                        </template>                        
                        <template slot="owner" scope="scope">
                          <v-menu offset-y>
                            <template v-slot:activator="{on, attrs}">
                              <v-btn small text v-bind="attrs" v-on="on">{{ scope.row.task_owner | username }}</v-btn>
                            </template>
                            <UserPicker @pick="changeTaskOwner($event, scope.row.task_id)"/>
                          </v-menu>
                        </template>
                        <template slot="date" scope="scope">
                          <date-range-picker ref="picker" opens="left" control-container-class="" :locale-data="{ 
                                            firstDay: 1,
                                            format: 'yyyy-mm-dd',
                                            applyLabel: '确定',
                                            cancelLabel: '取消',
                                            daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
                                            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                                          }" 
                                          minDate="2018-01-01" 
                                          maxDate="2050-12-31" 
                                          :dateRange="{startDate: scope.row.task_start_date, endDate: scope.row.task_end_date}" 
                                          :ranges="false" 
                                          @update="changeTaskDate($event, scope.row.task_id)" 
                                          :append-to-body="true">
                            <template v-slot:input="picker">
                              {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                            </template>
                          </date-range-picker>
                        </template>
                    </zk-table>
                  </v-row>
                </div>
                <div class="mt-7">
                  <v-row class="pa-2">
                    <v-spacer></v-spacer>
                    <v-btn class="mx-1" @click="close">取消</v-btn>
                    <v-btn @click="onOk" color="primary" class="mx-1">确定</v-btn>
                  </v-row>
                </div>                                
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card>
    <AddSort ref="addsort" v-on:setPersonalGoal="(param) => setParent(param)" v-on:setUnlink="setUnlink" />
  </v-dialog>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapGetters, mapActions } from 'vuex';
const quarters = [
  {
    text: '第一季度',
    value: '1'
  },
  {
    text: '第二季度',
    value: '2'
  },
  {
    text: '第三季度',
    value: '3'
  },
  {
    text: '第四季度',
    value: '4'
  }
]
export default {
  name: 'CopyWizard',
  props: ['object'],
  components: {
    DateRangePicker,
    UserPicker: () => import('@/components/common/UserPicker.vue'),
    AddSort: () => import('@/components/common/AddSort.vue')
  },
  computed: {
    ...mapGetters('okr', ['okrObj', 'okrDetail']),
    members: function() {
      let temp = []
      const members = this.object.ob_participant.split(',');
      members.map(member => {
        if(member != '') {
          temp.push(member);
        }
      })
      return temp;
    },
    parentName: function() {
      const index = this.okrObj.findIndex(object => object.ob_id == this.object.ob_parent_object);
      if(index != -1) {
        return this.okrObj[index].ob_name;
      } else {
        return ''
      }
      
    }
  },
  data() {
    return {
      dialog: false,
      step: 1,
      cycleType: null,
      years: [],
      cycleYear: null,
      cycle: null,
      cycles: [],
      taskCopyMode: 0,
      krCopyMode: 0,
      headers: [
        {
          text: '任务名称',
          align: 'start',
          value: 'task_name',
          width: 300
        },
        {
          text: '负责人',
          value: 'task_owner',
          width: 150
        },
        {
          text: '起止时间',
          value: 'date'
        }
      ],
      headers1: [
        {
          label: "",
          prop: "",
          width: "100px;"
        },
        {
          label: "任务名称",
          prop: "name",
          type: "template",
          template: "name"
        },
        {
          label: "负责人",
          prop: "owner",
          type: "template",
          template: "owner",
          width: "100px;"
        },
        {
          label: "起止时间",
          type: "template",
          template: "date"
        },
      ],
      objectTasks: [],
      props: {
        stripe: false,
        border: false,
        showHeader: true,
        showSummary: false,
        showRowHover: true,
        showIndex: false,
        treeType: true,
        isFold: true,
        expandType: false,
        selectionType: false,
      },
    }
  },
  watch: {
    cycleType: function(val) {
      if(val=='quarter') {
        let cycle;
        if(this.object.ob_cycle.includes('/')) {
          cycle = this.object.ob_cycle.split('/')         
        } else {
          cycle = this.getCycle()
        }        
        this.cycleYear = cycle[0];
        this.cycles = quarters;
        this.cycle = cycle[1];
      } else if(val=='month') {
        if(this.object.ob_cycle.includes('-')) {
          const cycle = this.object.ob_cycle.split('-')
          this.cycleYear = cycle[0];
          this.cycle = cycle[1]
        } else {
          const day = new Date();
          const month = day.getMonth();
          const year = day.getFullYear();
          this.cycle = String(month + 1);
          this.cycleYear = String(year);
        }
        this.cycles = this.inputMonths();
      } else if(val=='date') {
        this.cycleYear = ''
        this.cycle = 'date'
      } else {
        const day = new Date();
        const year = day.getFullYear();
        this.cycleYear = year.toString();
        this.cycle=''
      }
    },
    okrDetail: function () {
      this.objectTasks = [ ...this.okrDetail.ob_tasks ];
      this.okrDetail.ob_results.map(kr => {
        this.objectTasks = this.objectTasks.concat(kr.kr_tasks);
      });
    }
    // step: function(val) {
    //   if(val==3) {
    //     this.getDetail(this.object.ob_id);
    //   }      
    // }
  },
  methods: {
    ...mapActions('okr', ['getDetail']),
    open() {
      this.dialog = true;
      this.krCopyMode = 0;
      this.taskCopyMode = 0;
      this.step = 1;
      if(this.object.ob_cycle.includes('/')) {
        this.cycleType = 'quarter';        
      } else if(this.object.ob_cycle.includes('-')) {
        this.cycleType = 'month'
      } else if(this.object.ob_cycle.includes('date')){
        this.cycleType = 'date'
      } else {
        this.cycleType = 'year'
        this.cycleYear = this.object.ob_cycle
        this.cycle = ''
      }
      for(let i=0;i<31;i++) {
        this.years.push({
          text: `${2020+i}年`,
          value: `${2020+i}`
        })
      }
      this.getDetail(this.object.ob_id);
      // this.objectTasks.map(task => {
      //   delete task.task_id;
      //   delete task.task_parent_object;
      // })
    },
    onOk() {
      if(this.krCopyMode == 1) {
        delete this.object.ob_results;
      }
      if(this.taskCopyMode == 2) {
        this.objectTasks = [];
      }
      this.object.ob_tasks = this.objectTasks;
      this.$emit('clone');
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
    inputMonths() {
      let months = []
      for(let i=0;i<12;i++) {
        months.push({
          text: `${i+1}月`,
          value: `${i+1}`
        })
      }
      return months;
    },
    changeDate(param) {
      let start = param.startDate;
      let end = param.endDate;
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      this.object.ob_start_date = start
      this.object.ob_end_date = end
    },
    setOwner(e) {
      this.object.ob_owner = e.user.id
    },
    addMember(e) {
      let temp = this.object.ob_participant.split(',');
      temp.push(e.user.id);
      this.object.ob_participant = temp.join();
    },
    removeMember(id) {
      let temp = this.object.ob_participant.split(',');
      const index = temp.findIndex(member => member == id);
      temp.splice(index, 1);
      this.object.ob_participant = temp.join();
    },
    setParent(param) {
      this.object.ob_parent_object = param.ob_id;
    },
    openAlignDlg() {
      this.$refs.addsort.openDialog();
    },
    setUnlink() {
      this.ob_parent_object = 0;
    },
    setKrOwner(e, index) {
      this.object.ob_results[index].kr_owner = e.user.id
    },
    changeKrDate(e, index) {
      let start = e.startDate;
      let end = e.endDate;
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      this.object.ob_results[index].kr_start_date = start
      this.object.ob_results[index].kr_end_date = end
    },
    changeTaskDate(e, id) {
      let start = e.startDate;
      let end = e.endDate;
      start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
      end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
      const index = this.objectTasks.findIndex(task => task.task_id == id);
      this.objectTasks[index].task_start_date = start;
      this.objectTasks[index].task_end_date = end;
    },
    changeTaskOwner(e, id) {
      const index = this.objectTasks.findIndex(task => task.task_id == id);
      this.objectTasks[index].task_owner = e.user.id;
    }
  },
}
</script>